<template>
  <Layout>
    <PageHeader title="Add Patient Story" :items="items"> </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <div v-for="(item, index) in form.patient_stories" :key="item.id">
                <div class="row">
                  <b-form-group class="col-6">
                    <label>URL <span style="color: red">*</span></label>
                    <b-form-input
                      placeholder="Enter URL"
                      v-model="item.url"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.patient_stories.$each[index].url.$model,
                      }"
                    ></b-form-input>
                    <div
                      v-if="
                        submitted &&
                        !$v.form.patient_stories.$each[index].url.$model
                      "
                      class="invalid-feedback"
                    >
                      URL is required.
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label>Meta Title <span style="color: red">*</span></label>
                    <b-form-input
                      placeholder="Enter Meta Title"
                      v-model="item.meta_title"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.patient_stories.$each[index].meta_title
                            .$model,
                      }"
                    ></b-form-input>
                    <div
                      v-if="
                        submitted &&
                        !$v.form.patient_stories.$each[index].meta_title.$model
                      "
                      class="invalid-feedback"
                    >
                      Meta Title is required.
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label
                      >Meta Description <span style="color: red">*</span></label
                    >
                    <b-form-input
                      placeholder="Enter Meta Description"
                      v-model="item.meta_description"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.patient_stories.$each[index].meta_description
                            .$model,
                      }"
                    ></b-form-input>
                    <div
                      v-if="
                        submitted &&
                        !$v.form.patient_stories.$each[index].meta_description
                          .$model
                      "
                      class="invalid-feedback"
                    >
                      Meta Description is required.
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label>Meta Image <span style="color: red">*</span></label>
                    <b-form-file
                      accept=".jpg,.png,.svg"
                      @change="handleFileChange($event, index)"
                      ref="fileInput_${index}"
                      placeholder="Choose a file or drop it here"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.patient_stories.$each[index].meta_image
                            .$model,
                      }"
                    >
                    </b-form-file>
                    <div v-if="item.previewUrl" class="image-preview">
                      <img
                        :src="item.previewUrl"
                        alt="Image preview"
                        width="128px"
                        height="128px"
                        class="mt-2"
                      />
                    </div>
                    <div
                      v-if="
                        submitted &&
                        !$v.form.patient_stories.$each[index].meta_image.$model
                      "
                      class="invalid-feedback"
                    >
                      Meta Image is required.
                    </div>
                  </b-form-group>
                </div>
                <div
                  class="row mb-4"
                  v-if="$route.name == 'add-patient-stories'"
                >
                  <div class="col-3 socialLinks" style="align-items: start">
                    <b-button
                      variant="primary"
                      v-if="form.patient_stories.length - 1 == index"
                      @click.prevent="addPatientStory(index)"
                      :disabled="form.patient_stories[index].image == 0"
                    >
                      Add Patient Story
                    </b-button>
                    <b-button
                      variant="danger"
                      @click="removeImageStory(index)"
                      :disabled="form.patient_stories.length <= 0"
                    >
                      Remove Patient Story
                    </b-button>
                  </div>
                </div>
                <hr />
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
              >
                Save Data
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import clinicMixin from "@/mixins/ModuleJs/patient-stories";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, clinicMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      patient_stories: {
        $each: {
          url: { required },
          meta_title: { required },
          meta_description: { required },
          meta_image: { required },
        },
      },
    },
  },
};
</script>

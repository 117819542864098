var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":"Add Patient Story","items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"forum",attrs:{"enctype":"multipart/form-data"}},[_vm._l((_vm.form.patient_stories),function(item,index){return _c('div',{key:item.id},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("URL "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.patient_stories.$each[index].url.$model,
                    },attrs:{"placeholder":"Enter URL"},model:{value:(item.url),callback:function ($$v) {_vm.$set(item, "url", $$v)},expression:"item.url"}}),(
                      _vm.submitted &&
                      !_vm.$v.form.patient_stories.$each[index].url.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" URL is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("Meta Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.patient_stories.$each[index].meta_title
                          .$model,
                    },attrs:{"placeholder":"Enter Meta Title"},model:{value:(item.meta_title),callback:function ($$v) {_vm.$set(item, "meta_title", $$v)},expression:"item.meta_title"}}),(
                      _vm.submitted &&
                      !_vm.$v.form.patient_stories.$each[index].meta_title.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Meta Title is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("Meta Description "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.patient_stories.$each[index].meta_description
                          .$model,
                    },attrs:{"placeholder":"Enter Meta Description"},model:{value:(item.meta_description),callback:function ($$v) {_vm.$set(item, "meta_description", $$v)},expression:"item.meta_description"}}),(
                      _vm.submitted &&
                      !_vm.$v.form.patient_stories.$each[index].meta_description
                        .$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Meta Description is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("Meta Image "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-file',{ref:"fileInput_${index}",refInFor:true,class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.patient_stories.$each[index].meta_image
                          .$model,
                    },attrs:{"accept":".jpg,.png,.svg","placeholder":"Choose a file or drop it here"},on:{"change":function($event){return _vm.handleFileChange($event, index)}}}),(item.previewUrl)?_c('div',{staticClass:"image-preview"},[_c('img',{staticClass:"mt-2",attrs:{"src":item.previewUrl,"alt":"Image preview","width":"128px","height":"128px"}})]):_vm._e(),(
                      _vm.submitted &&
                      !_vm.$v.form.patient_stories.$each[index].meta_image.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Meta Image is required. ")]):_vm._e()],1)],1),(_vm.$route.name == 'add-patient-stories')?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-3 socialLinks",staticStyle:{"align-items":"start"}},[(_vm.form.patient_stories.length - 1 == index)?_c('b-button',{attrs:{"variant":"primary","disabled":_vm.form.patient_stories[index].image == 0},on:{"click":function($event){$event.preventDefault();return _vm.addPatientStory(index)}}},[_vm._v(" Add Patient Story ")]):_vm._e(),_c('b-button',{attrs:{"variant":"danger","disabled":_vm.form.patient_stories.length <= 0},on:{"click":function($event){return _vm.removeImageStory(index)}}},[_vm._v(" Remove Patient Story ")])],1)]):_vm._e(),_c('hr')])}),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[_vm._v(" Save Data ")])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }